import { Select } from "@chakra-ui/core";
import React, { forwardRef } from "react";

const StyledSelect = forwardRef((props, ref) => {
  return (
    <Select
      ref={ref}
      fontSize="md"
      pl={2}
      rounded={0}
      borderLeft={0}
      borderRight={0}
      borderTop={0}
      outline={0}
      borderColor="gray.400"
      _focus={{
        boxShadow: "none",
      }}
      {...props}
    />
  );
});

export default StyledSelect;
