import React from "react";
import { Button } from "@chakra-ui/core";
import { css } from "@emotion/core";

const MainButton = ({ primaryColor, secondaryColor, ...props }) => {
  return (
    <Button
      minHeight="48px"
      bg={primaryColor}
      color="white"
      _hover={css`
        background: ${secondaryColor};
      `}
      w="full"
      fontWeight="normal"
      type="button"
      cursor="pointer"
      size="lg"
      border={0}
      rounded={0}
      {...props}
    ></Button>
  );
};

export default MainButton;
