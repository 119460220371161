import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import MainButton from "./MainButton";
import StepBox from "./StepBox";
import StepHeader from "./StepHeader";
import StyledSelect from "./StyledSelect";

const Step2 = ({
  primaryColor,
  secondaryColor,
  currentStep,
  goToNextStep,
  checkName,
  register,
  unregister,
  setValue,
  errors,
  triggerValidation,
  setFormState,
  getValues,
  systemVersions,
  industries,
  stepHeaders,
}) => {
  const [systemChoice, setSystemChoice] = useState("start");

  const handleValueChange = (e, value) => {
    setValue("licenseType", value);
    setSystemChoice(value);
  };

  useEffect(() => {
    register({ name: "licenseType", required: true });
    setValue("licenseType", systemChoice);
    return () => {
      unregister("licenseType");
    };
  }, []);

  const handleValidateStep = async (e) => {
    await triggerValidation(["licenseType", "contactFormIndustry"]);

    if (Object.keys(errors).length === 0) {
      setFormState((formState) => ({
        ...formState,
        ...getValues(),
      }));
      goToNextStep();
    }

    return;
  };

  if (currentStep !== 2) {
    return null;
  }

  return (
    <StepBox>
      <Stack direction="column" justifyContent="space-between" h="full">
        {stepHeaders?.step2}
        <Stack spacing={10}>
          <FormControl>
            <FormLabel fontSize="lg" htmlFor="license-type">
              Wersja systemu:
            </FormLabel>
            <Box>
              <RadioGroup
                id="license-type"
                value={systemChoice}
                onChange={handleValueChange}
                spacing={1}
              >
                {systemVersions &&
                  systemVersions.map((version) => (
                    <Radio
                      key={version.code}
                      value={version.code}
                      borderColor="rgb(226, 232, 240)"
                      size="lg"
                    >
                      {version.name}
                    </Radio>
                  ))}
              </RadioGroup>
            </Box>
          </FormControl>
          {/* Branża */}
          <FormControl isInvalid={!!errors.contactFormIndustry}>
            <FormLabel fontSize="lg" htmlFor="contactFormIndustry">
              Branża
            </FormLabel>
            <StyledSelect
              ref={register({
                required: {
                  value: true,
                  message: "Branża jest wymagana",
                },
              })}
              type="text"
              id="contactFormIndustry"
              name="contactFormIndustry"
              placeholder="wybierz z listy"
            >
              {industries &&
                industries.map((industry) => (
                  <option key={industry.value} value={industry.value}>
                    {industry.text}
                  </option>
                ))}
            </StyledSelect>
            <FormErrorMessage>
              {errors.contactFormIndustry && errors.contactFormIndustry.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>
        <MainButton
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          onClick={handleValidateStep}
        >
          Przejdź do następnego kroku
        </MainButton>
      </Stack>
    </StepBox>
  );
};

export default Step2;
