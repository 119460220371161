import React from "react";
import { Box } from "@chakra-ui/core";

const StepBox = ({ children, ...props }) => {
  return (
    <Box className="step-box" bg="white" overflow="auto" maxW="100%" {...props}>
      <Box h="full" p={{ base: 8, xl: 16 }}>
        {children}
      </Box>
    </Box>
  );
};

export default StepBox;
