import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { Component } from "react";
import synergiusLogo from "../images/system-dla-agencji-reklamowych-marketingowych.png";
import Menu from "./menu";
import styles from "./header.module.css";
import { FaBars } from "react-icons/fa";

class Header extends Component {
  state = {
    open: false
  };
  render() {
    return (
      <nav className={styles.navBar}>
        <div className={styles.navBarContainer}>
          <FaBars
            className={styles.navBarToggle}
            onClick={() => this.setState({ open: !this.state.open })}
          />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 1170,
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
                display: `flex`,
                alignItems: "center"
              }}
            >
              <img
                style={{
                  margin: 0,
                  maxWidth: "220px",
                  padding: "3px 5px"
                }}
                src={synergiusLogo}
                alt=""
              />
            </Link>
            <Menu
              className={`${styles.mainNav} ${
                this.state.open ? styles.active : ""
              }`}
            />
          </div>
        </div>
      </nav>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
