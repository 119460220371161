import React, { forwardRef } from "react";
import { Input } from "@chakra-ui/core";

const StyledInput = forwardRef((props, ref) => {
  return (
    <Input
      ref={ref}
      fontSize="md"
      pl={2}
      rounded={0}
      borderLeft={0}
      borderRight={0}
      borderTop={0}
      outline={0}
      borderColor="gray.400"
      _focus={{
        boxShadow: "none",
      }}
      {...props}
    />
  );
});

export default StyledInput;
