import React from "react";
import { FormHelperText, Text } from "@chakra-ui/core";

const SystemName = ({ name, ...props }) => {
  return (
    <FormHelperText
      as="div"
      display="flex"
      alignContent="center"
      mt={2}
      mb={0}
      {...props}
    >
      <Text fontSize="sm" lineHeight="normal">
        <Text as="span" color="green.400">
          Adres Twojego systemu to:
        </Text>{" "}
        <Text as="span" color="gray.400">
          {name}.questy-cloud.pl
        </Text>
      </Text>
    </FormHelperText>
  );
};

export default SystemName;
