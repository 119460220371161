import { Box, Spinner, Stack, Text } from "@chakra-ui/core";
import React, { useEffect } from "react";
import StepBox from "./StepBox";

const Step4 = ({ currentStep, handleSubmit }) => {
  useEffect(() => {
    if (currentStep === 4) {
      handleSubmit();
    }
  }, [currentStep]);

  if (currentStep !== 4) {
    return null;
  }

  return (
    <StepBox>
      <Stack
        direction="column"
        align="center"
        justifyContent="space-between"
        h="full"
      >
        <Box textAlign="center" mb={8}>
          <Text fontWeight="bold" as="h2" fontSize="3xl">
            Tworzymy Twój system
          </Text>
           <Text>
          Za chwilę zostaniesz zalogowany jako{" "}
          <Text as="span" fontWeight="bold">
            Kierownik.
          </Text>
               </Text>
        </Box>
        <Spinner size="10rem" thickness="5px" color="gray.200"></Spinner>
        <Text>
          Dane do ponownego logowania wysłaliśmy na Twój adres e-mail.{" "}
          </Text>
      </Stack>
    </StepBox>
  );
};

export default Step4;
