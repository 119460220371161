export const getAddNewUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return "https://administracja.questy-cloud.pl/addNewJson.php";
  } else {
    return "https://administracja.eqcloud.pl/addNewJson.php";
  }
};

export const getCheckNameUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return "https://administracja.questy-cloud.pl/checkNameJson.php";
  } else {
    return "https://administracja.eqcloud.pl/checkNameJson.php";
  }
};
