import React from "react";
import { Box, Text } from "@chakra-ui/core";

const StepHeader = ({ headerText, subText }) => {
  return (
    <Box textAlign="center" mb={{ base: 2, lg: 8 }}>
      <Text fontWeight="bold" as="h2" fontSize={{ base: "2xl", lg: "3xl" }}>
        {headerText}
      </Text>
      <Text fontSize={{ base: "md", lg: "xl" }}>{subText}</Text>
    </Box>
  );
};

export default StepHeader;
