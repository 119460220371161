import React from "react";
import { Box } from "@chakra-ui/core";

const ProgressDivider = props => {
  return (
    <Box
      width="7px"
      height="100%"
      bg="rgb(237, 237, 237)"
      position="absolute"
      top={0}
      {...props}
    ></Box>
  );
};

export default ProgressDivider;
