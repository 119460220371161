import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "gatsby";
import styles from "./menu.module.css";
import MultiStepFormButton from "./MultiStepForm/MultiStepFormButton";

const Menu = ({ className }) => (
  <ul className={`${styles.menu} ${className}`}>
    <li className={styles.dropdown}>
      <Link to="/funkcje/">
        Funkcje <FaAngleDown />
      </Link>
      <div className={styles.dropdownContent}>
        <ul className={styles.dropdownMenu}>
         <li>
            <Link
              title="Zarządzanie szansami sprzedaży"
              to="/zarzadzanie-szansami-sprzedazy/"
            >
              Zarządzanie szansami sprzedaży
            </Link>
          </li>
          <li>
            <Link
              title="Koordynacja pracy zespołu"
              to="/koordynacja-pracy/"
            >
              Koordynacja pracy zespołu
            </Link>
          </li>
          <li>
            <Link
              title="Historia kontaktów z kontrahentami"
              to="/baza-wiedzy-o-kontrahentach/"
            >
              Historia kontaktów z kontrahentami
            </Link>
          </li>
          <li>
            <Link
              title="Zarządzanie projektami i zleceniami"
              to="/zarzadzanie-projektami/"
            >
              Zarządzanie projektami i zleceniami
            </Link>
          </li>
           <li>
            <Link
              title="Sprawna komunikacja"
              to="/komunikator/"
            >
              Sprawna komunikacja
            </Link>
          </li>
          <li>
            <Link
              title="Raportowanie"
              to="/raporty/"
            >
              Raportowanie
            </Link>
          </li>
          <li>
            <Link
              title="Baza produktów i usług"
              to="/baza-produktow/"
            >
              Baza produktów i usług
            </Link>
          </li>
            <li>
            <Link
              title="Integracja z pocztą"
              to="/integracja-z-poczta/"
            >
              Integracja z pocztą
            </Link>
          </li>
          <li>
            <Link
              title="Fakturowanie lub integracja z ERP"
              to="/faktury-i-platnosci/"
            >
              Fakturowanie lub integracja z ERP
            </Link>
          </li>
          <li>
            <Link
              title="Ofertowanie i zamówienia"
              to="/ofertowanie-i-zamowienia/"
            >
              Ofertowanie i zamówienia
            </Link>
          </li>
          <li>
            <Link
              title="Panel klienta"
              to="/panel-klienta/"
            >
              Panel klienta
            </Link>
          </li>
        </ul>
      </div>
    </li>
   <li>
      <Link to="/cennik-wynajem/">Cennik</Link>
    </li>
    <li>
      <Link to="/o-nas/">O nas</Link>
    </li>
    <li>
      <Link to="/kontakt/">Kontakt</Link>
    </li>
<li>
      <Link className={styles.special} to="/demo/">
        Wypróbuj teraz
      </Link>
    </li>
  </ul>
);

export default Menu;
