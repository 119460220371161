import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Text, Box } from "@chakra-ui/core";
import MultiStepForm from "./MultiStepFormContainer";
import StepHeader from "./StepHeader";

const LOGO_QUERY = graphql`
  query {
    logo: file(relativePath: { eq: "Synergius-CRM-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 330, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const MultiStepFormButton = ({ buttonProps }) => {
  const data = useStaticQuery(LOGO_QUERY);

  return (
    <MultiStepForm
      buttonProps={buttonProps}
      primaryColor="#389834"
      secondaryColor="#80B774"
      progressBarColor="green" // specjalny kolor z motywu
      logo={<Img fluid={data.logo.childImageSharp.fluid}></Img>}
      systemType="synergius"
      stepHeaders={{
        step1: (
          <StepHeader
            headerText="Wypróbuj Synergius CRM"
            subText="Dostęp w kilka sekund. Bez zobowiązań. 30 dni za darmo."
          ></StepHeader>
        ),
        step2: (
          <StepHeader
            headerText="Dokończ konfigurację"
            subText="Którą wersję wybierasz?"
          ></StepHeader>
        ),
        step3: (
          <StepHeader
            headerText="Wypróbuj Synergius CRM"
            subText="Dostęp w kilka sekund. Bez zobowiązań. 30 dni za darmo."
          ></StepHeader>
        ),
      }}
      industries={[
        {
          text: "Maszyny i urządzenia",
          value: "Maszyny i urządzenia",
        },
        {
          text: "Artykuły biurowe/szkolne",
          value: "Artykuły biurowe/szkolne",
        },
        {
          text: "Artykuły kosmetyczne",
          value: "Artykuły kosmetyczne",
        },
        {
          text: "Artykuły weterynaryjne",
          value: "Artykuły weterynaryjne",
        },
        {
          text: "Audio-video",
          value: "Audio-video",
        },
        {
          text: "Chemia gospodarcza",
          value: "Chemia gospodarcza",
        },
        {
          text: "Gastronomiczna",
          value: "Gastronomiczna",
        },
        {
          text: "Sektor IT",
          value: "Sektor IT",
        },
        {
          text: "Medyczna",
          value: "Medyczna",
        },
        {
          text: "Rolnicza",
          value: "Rolnicza",
        },
        {
          text: "Inna",
          value: "Inna",
        },
      ]}
      systemVersions={[
        {
          name: (
            <Text>
              <Text as="span" fontWeight="bold" textTransform="uppercase">
                Start
              </Text>{" "}
              (Zarządzanie szansami sprzedaży, Planowanie zadań)
            </Text>
          ),
          code: "start",
        },
        {
          name: (
            <Text>
              <Text as="span" fontWeight="bold" textTransform="uppercase">
                Standard
              </Text>{" "}
              (Start + Ofertowanie)
            </Text>
          ),
          code: "std",
        },
        {
          name: (
            <Text>
              <Text as="span" fontWeight="bold" textTransform="uppercase">
                PRO
              </Text>{" "}
              (Standard + Kanban + Gantt + Integracje z ERP)
            </Text>
          ),
          code: "pro",
        },
      ]}
    ></MultiStepForm>
  );
};

export default MultiStepFormButton;
