import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Text,
} from "@chakra-ui/core";
import React from "react";
import MainButton from "./MainButton";
import StepBox from "./StepBox";
import StepHeader from "./StepHeader";
import StyledInput from "./StyledInput";
import StyledSelect from "./StyledSelect";

const Step3 = ({
  primaryColor,
  secondaryColor,
  currentStep,
  goToNextStep,
  register,
  errors,
  triggerValidation,
  setFormState,
  getValues,
  stepHeaders,
}) => {
  const handleValidateStep = async (e) => {
    await triggerValidation([
      "contactFormName",
      "contactFormPhone",
      "contactFormCompanySize",
    ]);

    if (Object.keys(errors).length === 0) {
      await setFormState((formState) => {
        return {
          ...formState,
          ...getValues(),
        };
      });
      goToNextStep();
    }

    return;
  };

  if (currentStep !== 3) {
    return null;
  }

  return (
    <StepBox>
      <Stack direction="column" justifyContent="space-between" h="full">
        {stepHeaders?.step3}
        <Stack spacing={4}>
          {/* Imię i nazwisko */}
          <FormControl isInvalid={!!errors.contactFormName}>
            <FormLabel fontSize="lg" htmlFor="contactFormName">
              Imię i nazwisko
            </FormLabel>
            <StyledInput
              ref={register({
                required: {
                  value: true,
                  message: "Imię i nazwisko jest wymagane",
                },
              })}
              type="text"
              id="contactFormName"
              name="contactFormName"
              placeholder="np. Jan Nowak"
            />
            <FormErrorMessage>
              {errors.contactFormName && errors.contactFormName.message}
            </FormErrorMessage>
          </FormControl>
          {/* Telefon */}
          <FormControl isInvalid={!!errors.contactFormPhone}>
            <FormLabel fontSize="lg" htmlFor="contactFormPhone">
              Telefon
            </FormLabel>
            <StyledInput
              ref={register({
                required: {
                  value: true,
                  message: "Telefon jest wymagany",
                },
              })}
              type="text"
              id="contactFormPhone"
              name="contactFormPhone"
              placeholder="telefon"
            />
            <FormErrorMessage>
              {errors.contactFormPhone && errors.contactFormPhone.message}
            </FormErrorMessage>
          </FormControl>
          {/* Liczba pracowników */}
          <FormControl isInvalid={!!errors.contactFormCompanySize}>
            <FormLabel fontSize="lg" htmlFor="contactFormCompanySize">
              Liczba pracowników
            </FormLabel>
            <StyledSelect
              ref={register({
                required: {
                  value: true,
                  message: "Liczba pracowników jest wymagana",
                },
              })}
              type="text"
              id="contactFormCompanySize"
              name="contactFormCompanySize"
              placeholder="wybierz z listy"
            >
              <option value="jednoosobowa">Działalność jednoosobowa</option>
              <option value="mikro">Mikro (do 10 pracowników)</option>
              <option value="mała">Mała (do 49 pracowników)</option>
              <option value="średnia">Średnia (do 249 pracowników)</option>
              <option value="duża">Duża (250 pracowników lub więcej)</option>
            </StyledSelect>
            <FormErrorMessage>
              {errors.contactFormCompanySize &&
                errors.contactFormCompanySize.message}
            </FormErrorMessage>
          </FormControl>
          {/* Newsletter */}
          <FormControl isInvalid={!!errors.newsletter}>
            <Checkbox
              name="newsletter"
              ref={register()}
              borderColor="rgb(226, 232, 240)"
              size="lg"
            >
              <Text as="span" fontSize="xs">
                Chcę otrzymywać informacje o promocjach oraz porady (możesz
                anulować w dowolnym momencie).
              </Text>
            </Checkbox>
            <FormErrorMessage>
              {errors.newsletter && errors.newsletter.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>

        <MainButton
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          onClick={handleValidateStep}
        >
          Utwórz konto
        </MainButton>
      </Stack>
    </StepBox>
  );
};

export default Step3;
