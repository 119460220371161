import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Progress,
  useDisclosure,
} from "@chakra-ui/core";
import axios from "axios";
import debounce from "debounce-promise";
import { Link, navigate } from "gatsby";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CONTACT_FORM_TIMEOUT, DEMO_FORM_TIMEOUT } from "../../consts";
import { getAddNewUrl, getCheckNameUrl } from "../../utils";
import ProgressDivider from "./ProgressDivider";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const MultiStepForm = ({
  logo,
  primaryColor,
  secondaryColor,
  progressBarColor,
  systemVersions,
  industries,
  systemType,
  buttonProps,
  stepHeaders,
}) => {
  const {
    register,
    unregister,
    reset,
    errors,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      systemName: "",
      email: "",
      password: "",
      consent: false,
      systemType: null,
      contactFormName: "",
      contactFormPhone: "",
      contactFormIndustry: "",
      contactFormCompanySize: "",
      newsletter: false,
    },
  });

  const checkNameRequest = async (url, body) => {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.json();
  };

  const debouncedCheckNameRequest = debounce(checkNameRequest, 500);

  const checkName = async (systemName) => {
    const url = new URL(getCheckNameUrl());
    const body = { system_name: systemName };

    const response = await debouncedCheckNameRequest(url, body);

    switch (response?.message) {
      case "Name is valid":
        return true;
      case "System name cannot be empty":
        return "Nazwa systemu jest wymagana.";
      case "Name is already taken":
        return "System o podanej nazwie już istnieje.";
      case "Name contains illegal characters":
        return "Nazwa może składać się tylko z cyfr oraz liter (bez polskich znaków).";
      default:
        return false;
    }
  };

  const [gaCookie, setgaCookie] = useState("");
  const [utmCookie, setutmCookie] = useState("");
  const [utmMediumCookie, setutmMediumCookie] = useState("");
  const [utmCampaignCookie, setUtmCampaignCookie] = useState("");
  const [gclid, setGclid] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const cookies = Cookies.get();
    setgaCookie(cookies["_ga"]);
    setutmCookie(cookies["utm_source"]);
    setutmMediumCookie(cookies["utm_medium"]);
    setUtmCampaignCookie(cookies["utm_campaign"]);
    setGclid(cookies["gclid"]);
  });

  const maxStep = 3;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentStep, setCurrentStep] = useState(1);
  const [formState, setFormState] = useState({});

  const handleFormClose = () => {
    // Reset formularza
    reset();

    // Reset pełnego stanu formularza
    setFormState({});

    // Reset do pierwszego kroku
    setCurrentStep(1);

    onClose();
  };

  const goToNextStep = () => {
    setCurrentStep((step) => {
      return step + 1;
    });
  };

  const handleSubmit = async () => {
    const preparedData = {
      license_type: formState["licenseType"],
      system_type: systemType,
      system_name: formState["systemName"],
      system_email: formState["email"],
      system_password: formState["password"],
      contact_form_name: formState["contactFormName"],
      contact_form_phone: formState["contactFormPhone"],
      contact_form_industry: formState["contactFormIndustry"],
      contact_form_company_size: formState["contactFormCompanySize"],
      consent: formState["consent"],
      newsletter: formState["newsletter"],
      ga_value: gaCookie,
      utm_source: utmCookie,
      utm_medium: utmMediumCookie,
      utm_campaign: utmCampaignCookie,
      gclid,
    };

    try {
      const { data } = await axios.post(getAddNewUrl(), preparedData, {
        headers: {
          Accept: "application/json",
        },
        timeout: DEMO_FORM_TIMEOUT,
      });

      if (data?.location) {
        window.location.href = data.location;
      }

      // handleFormClose();
    } catch (err) {
      if (err.response) {
        if (err.response?.data?.location) {
          window.location.href = err.response.data.location;
        }
      } else {
        window.location.href = "/ups";
      }
    }
  };

  const getDividers = () => {
    let dividers = [];
    for (let i = 1; i <= maxStep - 1; i++) {
      dividers.push(
        <ProgressDivider
          key={i}
          left={`calc((100% / ${maxStep}) * ${i} )`}
        ></ProgressDivider>
      );
    }

    return dividers;
  };

  return (
    <>
      <Box>
        <Button
          cursor="pointer"
          variantColor="red"
          border={0}
          onClick={onOpen}
          {...buttonProps}
        >
          WYPRÓBUJ TERAZ
        </Button>
      </Box>
      <Drawer size="full" isOpen={isOpen} onClose={handleFormClose}>
        <DrawerOverlay
          transition="background-color 250ms"
          bg={currentStep > 1 ? "rgb(237, 237, 237)" : "blackAlpha.800"}
        />
        <DrawerContent boxShadow="none" bg={currentStep === 1 && "transparent"}>
          {/* {currentStep === 1 && ( */}
          <DrawerCloseButton
            color={currentStep <= 1 && "gray.300"}
            cursor="pointer"
            border={0}
            bg="transparent"
          />
          {/* )} */}
          <DrawerHeader position="absolute">
            {/* <IconButton
              position="absolute"
              left="50%"
              icon="plus-square"
              onClick={() => setCurrentStep(step => step + 1)}
            ></IconButton> */}
            <Link to="/">
              <Box maxW="330px" w={{ base: "50%" }}>
                {currentStep > 1 && logo}
              </Box>
            </Link>
          </DrawerHeader>

          <DrawerBody
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="full"
            height="full"
            maxH="100%"
            overflow="hidden"
            px={0}
          >
            <Box maxW="95%" overflow="auto" as="form" method="POST">
              <Step1
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                currentStep={currentStep}
                goToNextStep={goToNextStep}
                checkName={checkName}
                register={register}
                unregister={unregister}
                setValue={setValue}
                errors={errors}
                triggerValidation={trigger}
                setFormState={setFormState}
                getValues={getValues}
                stepHeaders={stepHeaders}
                control={control}
              ></Step1>
              <Step2
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                currentStep={currentStep}
                goToNextStep={goToNextStep}
                checkName={checkName}
                register={register}
                unregister={unregister}
                setValue={setValue}
                errors={errors}
                triggerValidation={trigger}
                setFormState={setFormState}
                getValues={getValues}
                systemVersions={systemVersions}
                industries={industries}
                stepHeaders={stepHeaders}
              ></Step2>
              <Step3
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                currentStep={currentStep}
                goToNextStep={goToNextStep}
                checkName={checkName}
                register={register}
                errors={errors}
                triggerValidation={trigger}
                setFormState={setFormState}
                getValues={getValues}
                handleSubmit={handleSubmit}
                stepHeaders={stepHeaders}
              ></Step3>
              <Step4
                currentStep={currentStep}
                handleSubmit={handleSubmit}
              ></Step4>
            </Box>
            {/* )} */}
            {/* </Box> */}
          </DrawerBody>

          {currentStep > 1 && (
            <DrawerFooter bg="transparent">
              <Box position="relative" w="full">
                <Progress
                  size="lg"
                  bg="white"
                  color={progressBarColor}
                  w="full"
                  value={((currentStep - 1) / maxStep) * 100}
                ></Progress>
                {getDividers()}
              </Box>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MultiStepForm;
