import React from "react";
import { Link } from "gatsby";
import styles from "./footer.module.css";

const Footer = () => {
  return (
    <footer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "40px 0px"
        }}
        className={styles.container}
      >
        <div className={styles.footerSection}>
        <p><strong>Podstawowe informacje</strong></p>
          <ul>
                     <li>
              <Link to="/o-nas/">O nas</Link>
            </li>
               <li>
              <Link to="/metody-finansowania/">Finansowanie</Link>
            </li>
            <li>
              <Link to="/faq/">FAQ</Link>
            </li>
            <li>
              <Link to="/faq-rodo/">FAQ RODO</Link>
            </li>
            <li>
              <a href="/program-partnerski/">Program partnerski</a>
            </li>
             <li>
              <Link to="/jaki-crm-dla-agencji-reklamowej/">Jaki system wybrać?</Link>
            </li>
             <li>
              <Link to="/crm-dla-agencji-marketingowej/">CRM dla agencji marketingowej</Link>
            </li>
             <li>
              <Link to="/cechy-systemu-crm-dla-agencji-reklamowej/">CRM dla agencji reklamowej</Link>
            </li>
          </ul>
        </div>
        <div className={styles.footerSection}>
                <p><strong>Kluczowe funkcje</strong></p>
          <ul>
          <li>
              <Link to="/zarzadzanie-szansami-sprzedazy/">Szanse sprzedaży</Link>
            </li>
             <li>
              <Link to="/koordynacja-pracy/">
                Koordynowanie pracy
              </Link>
              </li>
           <li>
              <Link to="/zarzadzanie-projektami/">Zarządzanie projektami</Link>
            </li>
            <li>
              <Link to="/baza-wiedzy-o-kontrahentach/">
                Baza kontrahentow
              </Link>
              </li>
            <li>
              <Link to="/faktury-i-platnosci/">Integracje z ERP</Link>
              </li>
            <li>
              <Link to="/narzedzia-do-pracy-zdalnej/">Narzędzia do pracy zdalnej</Link>
              </li>
          </ul>
        </div>


        <div id={styles.address} className={styles.footerSection}>
        
        <br />
        <p><strong>Dane kontaktowe</strong></p>
          <p>Questy</p>
          <p>ul. Głogowska 31/33</p>
          <p>60-702 Poznań</p>
          <p>
          <a
            className={styles.phoneNum}
            href="mailto:empireo@questy.pl"
            title="empireo@questy.pl"
          >
            empireo@questy.pl
          </a>
        </p>
          <p><a
            className={styles.phoneNum}
            href="tel:+48506504540"
            title="+48 506 504 540"
          >
            +48 506 504 540
          </a></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
